import {
  BoardCommonControllerService,
  BoardVo,
} from '@/__generated__/CommonApi';
import CardImg1 from '@/assets/img/building-future/climate_card_img1@2x.jpg';
import CardImg2 from '@/assets/img/building-future/climate_card_img2@2x.jpg';
import CardImg3 from '@/assets/img/building-future/climate_card_img3@2x.jpg';
import CardImg4 from '@/assets/img/building-future/climate_card_img4@2x.jpg';
import WedoIco1 from '@/assets/img/building-future/climate_obj1@2x.png';
import WedoIco2 from '@/assets/img/building-future/climate_obj2@2x.png';
import WedoIco3 from '@/assets/img/building-future/climate_obj3@2x.png';
import WedoIco4 from '@/assets/img/building-future/climate_obj4@2x.png';
import Obj from '@/assets/img/building-future/climate_sec2_obj2@2x.png';
import ObjM from '@/assets/img/building-future/climate_sec2_obj2M@2x.png';
import AccordionComponent, { AccordionItem } from '@/components/Accordion';
import { CampaignData } from '@/components/Card/CampaignCard';
import RelativeBoardCard from '@/components/Card/RelativeBoardCard';
import Container from '@/components/Container';
import Image from '@/components/Image';
import LinkSafe from '@/components/LinkSafe';
import {
  SectionCard,
  SectionStroy,
  SectionTarget,
  SectionTop,
  SectionWedo,
} from '@/components/Nodiscrimination';
import PageComponent from '@/components/PageComponent';
import { ProcessList } from '@/components/ProcessList';
import { SectionNews } from '@/components/Section';
import SectionHeader from '@/components/SectionHeader';
import SwiperBasic from '@/components/Swiper/SwiperBasic';
import ThumbnailFlex from '@/components/ThumbnailFlex';
import { Tit } from '@/components/Titles';
import YoutubeSingle from '@/components/YoutubeSingle';
import LayoutWithTitle from '@/layouts/LayoutWithTitle';
import { PageProps } from 'gatsby';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import 'swiper/components/navigation/navigation.min.css';
import 'swiper/components/pagination/pagination.min.css';
import 'swiper/components/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';

const card = [
  {
    id: 1,
    title: `어린이 중심 기후변화 전략과 대응 계획 수립`,
    dec: `유니세프는 기후변화 전략 및 재난 대응 계획에 어린이가 중요한 구성원이 되도록 정부 및 파트너와 협력합니다. 볼리비아에서 유니세프는 기후변화 적응 및 통합 수자원 관리 분야의 청소년 리더십을 강화하기 위해 ‘물과 기후변화에 관한 청소년 정상 회담’을 지원했습니다. 파푸아뉴기니에서는 학교에서 기후 재난 대응 계획을 만드는 데 어린이가 적극 참여합니다.`,
    img: CardImg1,
  },
  {
    id: 2,
    title: `어린이를 변화의 주체로 인식`,
    dec: `어린이는 어린이에게 영향을 미치는 문제를 결정할 때 의견을 말할 권리가 있습니다. 어른들은 어린이의 의견에 귀를 기울여야합니다(유엔아동권리협약 제12조). 유니세프는 유엔정상회의와 같이 어린이가 직접 참여할 수 있는 기회를 마련해 기후변화에 대한 그들의 목소리를 전합니다.`,
    img: CardImg2,
  },
  {
    id: 3,
    title: `기후변화와 환경 파괴로부터 어린이 보호`,
    dec: `유니세프는 학교, 보건소, 수자원 및 위생 시설 등 어린이 삶에 중요한 영향을 미칠 수 있는 요소들을 지원합니다. 이는 다가올 재난에 대해 대비하고, 오늘날의 불평등이 기후변화에 의해 악화될 가능성을 낮출 수 있습니다.<strong>1) 수자원 관리 시스템 지원</strong>기후변화 피해 중 하나는 홍수, 가뭄, 악천후로 인한 물 부족과 오염입니다. 유니세프는 태양 에너지를 이용한 물 공급, 효율적으로 수자원을 사용하는 관리 시스템을 지원합니다.<strong>2) 학교의 지속 가능한 에너지 사용과 재난 위험 대응</strong>학교 교육은 자연재해로부터 어린이를 보호하기 위한 가장 좋은 방법입니다. 태양열과 같은 재생 가능 에너지는 전력 공급이 열악한 지역 학교의 전등, 급식 등 교육 제반 시설에 유용하게 사용 됩니다.<strong>3) 보건 시설의 지속 가능한 에너지 사용과 재난 위험 대응</strong>조명, 난방 기기, 의료기기 사용을 위한 기본적인 전기 공급은 보건 서비스에서 매우 중요한 역할을 합니다. 유니세프는 태양광 활동 백신 저온 보관 기술 지원 등 에너지 공급이 안정적으로 이뤄질 수 있도록 돕고, 재난 회복탄력성을 높이는 대책을 마련하는 데 협력합니다.`,
    img: CardImg3,
  },
  {
    id: 4,
    title: `온실 가스 배출 및 오염 감소 노력`,
    dec: `유니세프는 각국이 대기 오염을 모니터링하고 폐렴이나 기타 호흡기 질환에 대응하도록 지원합니다. 특히 기후 위기 대응 전략이 미비하거나 실행하기 어려운 국가 및 지역을 우선으로 지원합니다.`,
    img: CardImg4,
  },
];

const wedo = [  
  {
    id: 1,
    tit: `2019년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>56개국</strong>에 <br />아동친화적인  <br />기후변화 정책  <br />도입 촉구`,
          },
        ],
      },
    ],
  },
  {
    id: 2,
    tit: `2020년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>56개국</strong>에  <br />아동친화적인  <br />기후변화 정책  <br />도입 촉구`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>74개국</strong>에서 저탄소  <br />개발을 촉진하는  <br />어린이 대상  <br />프로그램 시행`,
          },
        ],
      },
    ],
  },
  {
    id: 3,
    tit: `2021년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>81개국</strong>에 아동친화적인 <br/>기후변화 정책 도입 촉구`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `중저소득 국가에 태양열로<br/> 운영되는 수도 시스템 <br/><strong>4,257개</strong> 건설`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `캠페인 옹호 활동을 통해<br/> 기후 위기에 처한 어린이 <br/><strong>70만 명</strong>에게 도달`,
          },
        ],
      },
    ],
  },
  {
    id: 4,
    tit: `2022년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `제27차 <strong>유엔기후변화협약(COP27)</strong>에서 어린이 역할 강조`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>320만 명</strong>에게 기후변화에 강한 위생 시설 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `온·오프라인을 통해 어린이 <strong>100만 명</strong>과 함께 기후변화 대응 활동(100개국 이상)`,
          },
        ],
      },
    ],
  },
  {
    id: 5,
    tit: `2023년 주요 성과`,
    child: [
      {
        id: 1,
        img: WedoIco3,
        desc: [
          {
            dec: `<strong>540만 명</strong>에게 기후변화에 강한 수도 시설 지원`,
          },
        ],
      },
      {
        id: 2,
        img: WedoIco4,
        desc: [
          {
            dec: `<strong>480만 명</strong>에게 기후변화에 강한 위생 시설 제공`,
          },
        ],
      },
      {
        id: 3,
        img: WedoIco2,
        desc: [
          {
            dec: `<strong>68개국</strong>에서 어린이 중심 기후 대응 프로그램을 채택하도록 지원`,
          },
        ],
      },
    ],
  },
];

const NavigationButton = styled.div``;
const H2 = styled.h2``;

export interface storyData {
  id: string | number;
  subject: string;
  contents: string;
  optData1: string;
  image: string;
}

const ClimateChange: React.FC<PageProps> = ({ location }) => {
  const [slideData, setSlideData] = useState<storyData[]>([]);
  const [thumbs, setThumbs] = useState<CampaignData[]>([]);

  const loadSlideData = useCallback(async () => {
    try {
      const {
        data: childStory,
      } = await BoardCommonControllerService.foListAllUsingGet({
        boardCategoryCode: ['ST06'],
        outDateDispYn: `Y`,
      });
      setSlideData(
        childStory.map((story: BoardVo) => ({
          id: story.boardIndexNumber,
          subject: story.subject,
          contents: story.contents,
          optData1: story.optData1,
          image: story.imgAttGrpNoTn,
        })),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  const loadThumbs = useCallback(async () => {
    try {
      const { data } = await BoardCommonControllerService.foListUsingGet({
        boardCategoryCode: ['280', '285', '290'],
        pagePerCount: 8,
        category: ['A010'],
      });
      const articles = data as any;
      setThumbs(
        articles
          .map((article: BoardVo) => ({
            id: article.boardIndexNumber,
            title: article.subject,
            pcImage: article.imgAttGrpNoTn,
            mobileImage: article.imgAttGrpNoTn,
            link: `/what-we-do/news/${article.boardIndexNumber}`,
            date: article.firstRegisterDate,
            boardCategoryCode: article.boardCategoryCode,
          }))
          .sort((a, b) => new Date(b.date) - new Date(a.date) || b.id - a.id),
      );
    } catch (e) {
      console.error(e);
    }
  }, []);

  useEffect(() => {
    loadSlideData();
    loadThumbs();
  }, [loadSlideData, loadThumbs]);

  return (
    <LayoutWithTitle
      location={location}
      title="기후위기 대응"
      description="for every child, a safe environment"
    >
      <SectionTop className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <H2>
              <Tit size="s1-5" color="sky" weight="normal">
                <PageComponent id="title1">
                  모든 어린이는 안전하고 깨끗한 환경에서{` `}
                  <br className="small-hide" />
                  자랄 권리가 있습니다
                </PageComponent>
              </Tit>
            </H2>
            <p className="head-dec">
              <PageComponent id="content1">
                기후변화는 어린이의 모든 권리를 위협합니다. 기후변화에 가장
                책임이 없는 어린이가 기후변화 피해를 오롯이 떠안게 됩니다. 기후
                위기를 해결하려는 노력, 즉 기후 행동은 막대한 경제적, 사회적
                혜택을 보장할 수 있는 기회이자 지속가능발전목표(Sustainable
                Development Goals; SDGs) 달성에 한 걸음 가까워지는 방법입니다.
                유니세프는 어린이가 안전하고 깨끗한 환경에서 살 수 있도록 다음
                네 가지 접근 방법을 기본으로 전 세계 및 지역 차원의 파트너와
                협력합니다.
              </PageComponent>
            </p>
          </SectionHeader>
          <PageComponent id="youtube1">
            <YoutubeSingle videoId="yp1OtEfjr5M" />
          </PageComponent>
        </Container>
      </SectionTop>

      <SectionCard className="by-sub-main-layout">
        <Container>
          <ThumbnailFlex thumbs={card} />
        </Container>
      </SectionCard>

      <SectionTarget className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc sec-header">
            <div className="col-header">
              <h2>
                <Tit size="s1">
                  2022-2025 유니세프 <br />
                  중점 사업 목표
                </Tit>
              </h2>
              <ul className="blit">
                <li>
                  기후 변화로 인해 어린이가 직면하는 위험 <strong>완화</strong>
                </li>
                <li>
                  기후 변화의 영향을 최소화할 수 있도록 온실가스 배출{' '}
                  <strong>감소</strong>
                </li>
              </ul>
            </div>
            <Image pcSrc={Obj} mobileSrc={ObjM} />
          </SectionHeader>
        </Container>
      </SectionTarget>

      <SectionWedo className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">
                기후위기 대응을 위해,
                <br />전 세계 파트너와 함께 유니세프가 한 일
              </Tit>
            </h2>
          </SectionHeader>

          <AccordionComponent initialKey={wedo[wedo.length - 1].id}>
            {wedo.map((row) => (
              <AccordionItem
                key={row.id}
                toggleKey={row.id}
                renderToggle={(onClick) => (
                  <NavigationButton
                    onClick={onClick}
                    className="accordion-opener"
                  >
                    {row.tit}
                  </NavigationButton>
                )}
              >
                <div className="accordion-body">
                  <ProcessList
                    itemData={row.child}
                    type={row.col ? row.col : ``}
                  />
                </div>
              </AccordionItem>
            ))}
          </AccordionComponent>
        </Container>
      </SectionWedo>

      <SectionStroy className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <h2>
              <Tit size="s1">어린이 이야기</Tit>
            </h2>
          </SectionHeader>
        </Container>
        <div className="section-content">
          <div className="obj t1" />
          <div className="obj t2" />
          <Container>
            <div className="content-container">
              <SwiperBasic
                css={`
                  padding: 0;
                `}
              >
                <Swiper slidesPerView="auto" navigation>
                  {slideData.map((row) => (
                    <SwiperSlide key={row.id}>
                      <div className="story-flex">
                        <div className="col-img">
                          <Image pcSrc={row.image} mobileSrc={row.image} />
                        </div>
                        <div className="col-dec">
                          <header>
                            <Tit size="s4" color="white">
                              &ldquo;{row.subject}&rdquo;
                            </Tit>
                            {row.optData1 !== null && row.optData1 !== `` && (
                              <p> - {row.optData1} </p>
                            )}
                          </header>
                          <p className="dec">{row.contents}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </SwiperBasic>
            </div>
          </Container>
        </div>
      </SectionStroy>

      <SectionNews className="by-sub-main-layout">
        <Container>
          <SectionHeader className="with-desc">
            <div className="flex-middle">
              <h2>
                <Tit size="s1">최근 소식</Tit>
              </h2>
              <LinkSafe to="/what-we-do/news?category=A010" className="more">
                <span className="txt">더 보기</span>
              </LinkSafe>
            </div>
          </SectionHeader>
          {thumbs.length > 0 && (
            <RelativeBoardCard
              newslist={thumbs}
              isDate={false}
              isSwiper
              overflowHidden
            />
          )}
        </Container>
      </SectionNews>
    </LayoutWithTitle>
  );
};

export default ClimateChange;
